import React from 'react'
import SbEditable from 'storyblok-react'
import { render } from 'storyblok-rich-text-react-renderer'

const Youtube = ({blok}) => {
  return (
    <SbEditable content={blok}>
      <div className="flex">
        <div className={blok.Align}>
          <div className="row-video">
            <div className="video">
              <iframe
                src={blok.Embed}
                title="YKK"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            </div>
          </div>

        </div>
      </div>
    </SbEditable>
  )
}

export default Youtube

import React from "react"
import SbEditable from 'storyblok-react'

const Video = (blok) => (
  <SbEditable content={blok}>
    <section className="video-section">
      <div className="container container--slim">
        <div className="video">
          <iframe
            src="https://www.youtube.com/embed/x1Nb5lWZY9U"
            title="YKK"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </div>
      </div>
    </section>
  </SbEditable>
)
export default Video

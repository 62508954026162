import React, { Component } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import SbEditable from 'storyblok-react'
import { render } from 'storyblok-rich-text-react-renderer'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'
import CloudinaryVideo from "./cloudinaryvideoembed"
import Scrollbar from 'smooth-scrollbar'

class TestimonialsRow extends Component {
  render() {
    const blok = this.props.blok
    return (
      <SbEditable content={blok}>
        <section className="testimonials-row">
          <div className="container">
            <div className={`flex ${(blok.Flip) ? `flex--flipped` : ``}`}>
              <div className={`testimonials-row__text ${(blok.ReduceTextSize) ? 'testimonials-row__text--wide' : ''} ${(blok.Stacked) ? 'testimonials-row__text--stacked' : ''}`}>
                <p className={(blok.ReduceTextSize) ? `h4 alt` : `h3`} dangerouslySetInnerHTML={{__html: `"${blok.Text}"`}}></p>
                <p className="testimonials-row__info">{blok.Name}&nbsp;&nbsp;-&nbsp;&nbsp;{blok.Position}, {blok.Company}</p>
              </div>
              {
                (blok.Image) ? (
                  <div className={`testimonials-row__image ${(blok.ReduceTextSize) ? 'testimonials-row__image--slim' : ''} ${(blok.Stacked) ? 'testimonials-row__image--stacked' : ''}`}>
                    <Img loading="eager" fluid={
                      getFluidGatsbyImage(blok.Image, {
                        maxWidth: 900
                      })
                    } />
                  </div>
                )

                : ''
              }

            </div>
          </div>
        </section>
      </SbEditable>
    )
  }
}

export default TestimonialsRow

import React, { Component } from "react"
import SbEditable from "storyblok-react"
import { render } from "storyblok-rich-text-react-renderer"
// Image imports
import squarePatternIcon from "../images/square-pattern-icon_2.svg"

const ProductIntro = ({ blok }) => {
  return (
    <SbEditable content={blok} blok={blok._uid}>
      <section className="product-intro">
        <div class="product-intro__container container">
          <div className="product-intro__description">
            {render(blok.description)}
          </div>
          {/* <img
            src={squarePatternIcon}
            alt="Square zip pattern icon"
            aria-hidden={true}
            className="product-intro__icon"
          /> */}
        </div>
      </section>
    </SbEditable>
  )
}

export default ProductIntro

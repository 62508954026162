import React from 'react'
import SbEditable from 'storyblok-react'
import { render } from 'storyblok-rich-text-react-renderer'

const Title = ({blok}) => {
  return (
    <SbEditable content={blok}>
      <div className="flex">
        <div className={blok.Align}>
          <div className={`row-text${(blok.reset_bottom_margin) ? ' row-text--reset-pad' : ''}`}>
            {render(blok.Text)}
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default Title

import React from "react"
import SbEditable from "storyblok-react"
import CloudinaryVideo from "./cloudinaryvideoembed"
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'

const ImageLarge = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <div className="product-image-large">
        <div className="container">
          <div className="product-image-large__image-wrapper">
            {(blok.ID) ?
              <CloudinaryVideo
                publicId={`ykk/${blok.ID}`}
                fadeInOut={true}
                autoPlay={true}
                class_list="product-image-large__cloudinary-video"
              />
                :
              <Img loading="eager" className="image-block__image" fluid={
                getFluidGatsbyImage(blok.Image, {
                  maxWidth: 1200
                })
              } />
            }
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default ImageLarge

import React, { Component } from "react"
import Link from "gatsby-link"
import SbEditable from "storyblok-react"
import fpgIcon from "../images/fpg.svg"
import { useStaticQuery, graphql } from "gatsby"

const Footer = ({ children }) => {
  const { settings } = useStaticQuery(graphql`
    query FooterSettings {
      settings: allStoryblokEntry(
        filter: { field_component: { eq: "settings" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
    }
  `)

  let activeLanguage = "en"

  let correctSetting = settings.edges.filter(
    edge => edge.node.full_slug.indexOf(activeLanguage) > -1
  )
  let hasSetting =
    correctSetting && correctSetting.length ? correctSetting[0].node : {}
  let content =
    typeof hasSetting.content === "string"
      ? JSON.parse(hasSetting.content)
      : hasSetting.content
  let parsedSetting = Object.assign({}, content, { content: content })

  return (
    <footer className="site-footer">
      <div aria-hidden={true} className="white-bar"></div>
      <div className="site-footer__nav">
        {parsedSetting &&
          parsedSetting.content.main_navi.map((navitem, index) => (
            <SbEditable content={navitem} key={navitem._uid}>
              <Link
                key={index}
                to={`/${navitem.link.cached_url
                  .replace("en/", "")
                  .replace("home", "")}`}
                prefetch="true"
                className="site-footer__nav-link"
              >
                {navitem.name}
              </Link>
            </SbEditable>
          ))}
      </div>
      <div className="site-footer__copy">
        {/* <img src={fpgIcon} alt="Fastening Products Group" /> */}
        <p className="footer-legal">
          Aquaguard®, Vislon®, Natulon® Conceal®, Flexseal®, Aquaseal®, Flatknit®, Acroplating®, Wetstop®, TouchLink®, YKK® and the combined mark of YKK Little Parts. Big Difference.® are registered trademarks of YKK CORPORATION in Japan and other countries/regions. Natulon Plus™ is a trademark of YKK Corporation. The "ZIP &amp; RIDE" logo is a trading style of YKK Europe Limited.
          <br />
          Other trademarks are the property of their respective owners.
        </p>
        <p>
          &copy; YKK Europe Limited
          <br />
          <a
            href="https://ykkeurope.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </footer>
  )
}

export default Footer

import React, { Component } from 'react'
import SbEditable from 'storyblok-react'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'
import gridOfDots from "../images/grid-of-dots.svg"
import CloudinaryVideo from "./cloudinaryvideoembed"

class ImageBlock extends Component {
  constructor(props) {
    super(props)
    this.sectionRef = React.createRef();
  }

  componentDidMount() {

  }

  render() {
    const blok = this.props.blok
    return (
      <SbEditable content={blok}>
        <section ref={this.sectionRef} className={`image-block ${(blok.Alignment) ? `image-block--${blok.Alignment}` : ``}`}>
          <div className={`${(blok.Alignment) ? `` : 'container container--slim'}`}>
            {
              (blok.Title) ? (
                <p className="h4 text--center text--space-bot">{blok.Title}</p>
              ) : ''
            }
            <div className="fade">
            {(blok.Video_ID) ?
              <div className="image-block__image">
                <CloudinaryVideo
                  publicId={`ykk/${blok.Video_ID}`}
                  fadeInOut={true}
                  class_list="cover-bg"
                  autoPlay={true}
                />
              </div>
              :
              <Img loading="eager" className="image-block__image" fluid={
                getFluidGatsbyImage(blok.Image, {
                  maxWidth: 1200
                })
              } />
            }
            </div>
          </div>
          <img alt="Dots icon" ariaHidden={true} className="grid-of-dots image-block__grid-of-dots" src={gridOfDots} />
        </section>
      </SbEditable>
    )
  }
}

export default ImageBlock

import React, { Component } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import SbEditable from 'storyblok-react'
import { render } from 'storyblok-rich-text-react-renderer'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'
import CloudinaryVideo from "./cloudinaryvideoembed"
import Scrollbar from 'smooth-scrollbar'
import { isMobile, isTablet } from "react-device-detect"

class ImageAndTextBlock extends Component {
  constructor(props) {
    super(props)
    this.sectionRef = React.createRef();
    this.imageRef = React.createRef();
    this.textRef = React.createRef();
  }

  componentDidMount() {

    gsap.registerPlugin(ScrollTrigger);
    if(!isMobile && !isTablet) {
      const scroller = document.querySelector('#scroller')
      const bodyScrollBar = Scrollbar.init(scroller, { delegateTo: document, alwaysShowTracks: true, syncCallbacks: true })

      ScrollTrigger.scrollerProxy("#scroller", {
        scrollTop(value) {
          if (arguments.length) {
            bodyScrollBar.scrollTop = value;
          }
          return bodyScrollBar.scrollTop;
        }
      });
      bodyScrollBar.addListener(ScrollTrigger.update);

      ScrollTrigger.defaults({ scroller: scroller });
    }


    let animTimeline = gsap.timeline();
    let textTimeline = gsap.timeline();

    // let rule = CSSRulePlugin.getRule(".crop-reveal-text:before");

    let imageWrapperRef = this.imageRef.current.getElementsByClassName('gatsby-image-wrapper')

    if(imageWrapperRef) {
      animTimeline.from(imageWrapperRef, {
        scale:1.75,
        duration: 0.5,
        ease: "SlowMo.ease",
      },'-=0.5').from(this.imageRef.current, {
        clipPath:"inset(35vh 0 35vh 0)",
        scale:0.75,
        opacity: 0,
        duration: 0.5,
        ease: "SlowMo.ease",
      },'-=0.5')
    } else {
      animTimeline.from(this.imageRef.current, {
        clipPath:"inset(35vh 0 35vh 0)",
        scale:0.75,
        opacity: 0,
        duration: 0.5,
        ease: "SlowMo.ease",
      },'-=0.5')
    }


    textTimeline.to(this.textRef.current, {
      y: -500,
    })

    // animTimeline.to(this.textRef.current, {
    //   y: 0,
    //   opacity: 1,
    // }).from(this.imageRef.current, {
    //   clipPath:"inset(35vh 0 35vh 0)",
    //   opacity: 0,
    //   duration: 0.5,
    //   ease: "SlowMo.ease",
    // },'-=0.5')


    let stickySection = ScrollTrigger.create({
      animation: animTimeline,
      trigger: this.sectionRef.current,
      start: "top 50%",
      end: () => "+=" + window.innerHeight/2,
      // pin: true,
      // pinSpacing: true,
      scrub: true,
      toggleActions: "play reverse play reverse"
    });

    let stickyTextSection = ScrollTrigger.create({
      animation: textTimeline,
      trigger: this.sectionRef.current,
      start: "top 50%",
      end: () => "+=" + window.innerHeight,
      scrub: true,
      toggleActions: "play reverse play reverse"
    });


    // let sectionScroll = gsap.from('.scale-in', {
    //   // height: 0,
    //   clipPath:"inset(35vh 0 35vh 0)",
    //   opacity: 0,
    //   scrollTrigger: {
    //     trigger: this.sectionRef.current,
    //     start: "top 15%",
    //     end: "+=200", // slow down the time it takes to complete the anim
    //     // pin: true,
    //     // markers: true,
    //     scrub: true
    //   }
    // });
    //
    // let textLayerScroll = gsap.from('.text-layer-i', {
    //   translateY: 1050,
    //   scrollTrigger: {
    //     trigger: this.sectionRef.current,
    //     start: "top 100%",
    //     end: () => "+=" + window.innerHeight*2,
    //     // pin: true,
    //     scrub: true,
    //   }
    // });

    // gsap.utils.toArray('.scale-in').forEach(section => {
    //   gsap.from(section, {
    //     height: 0,
    //     // opacity: 0,
    //     duration: 2.5,
    //     ease: "expo.inOut",
    //     // ease: "SlowMo.ease",
    //     scrollTrigger: {
    //       trigger: section,
    //       start: "top bottom",
    //       toggleActions: "play none none none",
    //       // markers:true
    //     },
    //   });
    // });
    // gsap.utils.toArray('.text-layer-i').forEach(section => {
    //   gsap.to(section, {
    //     translateY: "50vh",
    //     scrollTrigger: {
    //       trigger: section,
    //       start: "top bottom",
    //       end: () => "+=" + window.innerHeight*2,
    //       scrub: true,
    //     },
    //   });
    // });
  }

  render() {
    const blok = this.props.blok
    return (
      <SbEditable content={blok}>
        <section ref={this.sectionRef} className={`image-text-block ${(blok.flip) ? `image-text-block--flipped` : ``}`}>
          <div className={ (blok.video_id) ? 'image-text-block__image image-text-block__image--video' : 'image-text-block__image' }>
            <div ref={this.imageRef}>
              {(blok.video_id) ?
                <CloudinaryVideo
                  publicId={`ykk/${blok.video_id}`}
                  fadeInOut={true}
                  class_list="cover-bg"
                  autoPlay={true}
                />
                :
              <Img loading="eager" fluid={
                getFluidGatsbyImage(blok.image, {
                  maxWidth: 2000
                })
              } />
              }
            </div>
          </div>

          {
            (blok.text) ? (
              <div className="image-text-block__text">
                <div ref={this.textRef}>
                {render(blok.text)}
                </div>
              </div>
            ) : (
              <div ref={this.textRef}></div>
            )
          }



        </section>
      </SbEditable>
    )
  }
}

export default ImageAndTextBlock

import React, { Component } from "react"
import DynamicComponent from "./DynamicComponent"
import SbEditable from "storyblok-react"
import CloudinaryVideo from "./cloudinaryvideoembed"
import gridOfDots from "../images/grid-of-dots.svg"
import dashedLineVert from "../images/dashed-line-vert.svg"
import { render } from "storyblok-rich-text-react-renderer"
// import Products from "./products"
import Footer from "./Footer"
import Scrollbar from "smooth-scrollbar"
import ScaleSpeedPlugin from "./ScaleSpeedPlugin"
import { CSSRulePlugin } from "gsap/CSSRulePlugin"
import { CSSPlugin } from "gsap/CSSPlugin"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Img from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-storyblok-image"
import Link from "gatsby-link"
import { isMobile, isTablet } from "react-device-detect"
import SampleButton from "./SampleButton"
import { useStaticQuery, graphql } from "gatsby"

let bodyScrollBar
let scroller

// gsap.registerPlugin(ScrollTrigger)

class Product extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollerInitialised: false,
      loading: true,
    }

    this.scrollerRef = React.createRef()
    this.loadingComplete = this.loadingComplete.bind(this)
  }

  componentDidMount() {
    // gsap.registerPlugin(ScrollTrigger)
    // gsap.registerPlugin(CSSRulePlugin)
    // gsap.registerPlugin(CSSPlugin)
    // scroller = document.querySelector("#scroller")
    
    // const scroller = this.scrollerRef.current
    // if (!isMobile && !isTablet) {
    //   const scroller = document.querySelector("#scroller")

    //   bodyScrollBar = Scrollbar.init(scroller, {
    //     delegateTo: document,
    //     alwaysShowTracks: true,
    //     syncCallbacks: true,
    //   })

    //   ScrollTrigger.scrollerProxy("#scroller", {
    //     scrollTop(value) {
    //       if (arguments.length) {
    //         bodyScrollBar.scrollTop = value
    //       }
    //       return bodyScrollBar.scrollTop
    //     },
    //   })
    //   bodyScrollBar.addListener(ScrollTrigger.update)
    //   ScrollTrigger.defaults({ scroller: scroller })
    // }
    
    // gsap.utils
    //   .toArray(".bullet-point-list__title, .product-list__title")
    //   .forEach((titleEl, i) => {
    //     gsap.from(titleEl, {
    //       y: 30,
    //       opacity: 0,
    //       duration: 0.75,
    //       scrollTrigger: {
    //         trigger: titleEl,
    //         start: "top 85%",
    //         toggleActions: "play none none reverse",
    //       },
    //     })
    //   })

    // gsap.utils.toArray(".bullet-point-list__list p").forEach((bullet, i) => {
    //   gsap.from(bullet, {
    //     y: 20,
    //     opacity: 0,
    //     duration: 0.75,
    //     scrollTrigger: {
    //       trigger: bullet,
    //       start: "top 85%",
    //       toggleActions: "play none none reverse",
    //     },
    //   })
    // })

    // gsap.utils.toArray(".product-intro__description").forEach((textEl, i) => {
    //   gsap.from(textEl, {
    //     y: 30,
    //     opacity: 0,
    //     duration: 0.75,
    //     scrollTrigger: {
    //       trigger: textEl,
    //       start: "top 85%",
    //       toggleActions: "play reverse play reverse",
    //     },
    //   })
    // })

    // gsap.utils
    //   .toArray(".zip-icon, .product-list__zip-icon")
    //   .forEach((gif, i) => {
    //     gsap.set(gif, { clipPath: "inset(100% 0% 0% 0%)" })
    //     gsap.to(gif, {
    //       clipPath: "inset(0% 0% 0% 0%)",
    //       scrollTrigger: {
    //         trigger: gif,
    //         start: "top 55%",
    //         end: () => "+=100",
    //         scrub: true,
    //         // markers: true,
    //         toggleActions: "play reverse play reverse",
    //       },
    //     })
    //   })

    // gsap.utils.toArray(".product-intro__icon").forEach((gif, i) => {
    //   gsap.set(gif, { clipPath: "inset(0% 100% 0% 0%)" })
    //   gsap.to(gif, {
    //     clipPath: "inset(0% 0% 0% 0%)",
    //     scrollTrigger: {
    //       trigger: gif,
    //       start: "top 55%",
    //       end: () => "+=100",
    //       scrub: true,
    //       // markers: true,
    //       toggleActions: "play reverse play reverse",
    //     },
    //   })
    // })

    // gsap.utils
    //   .toArray(
    //     ".product-image-small__image-wrapper, .product-image-large__image-wrapper, .product-video-large__video-wrapper"
    //   )
    //   .forEach((gif, i) => {
    //     gsap.set(gif, { opacity: 0, scale: 0.9 })
    //     gsap.to(gif, {
    //       opacity: 1,
    //       scale: 1,
    //       scrollTrigger: {
    //         trigger: gif,
    //         start: "top 55%",
    //         end: () => "+=100",
    //         scrub: true,
    //         // markers: true,
    //         toggleActions: "play reverse play reverse",
    //       },
    //     })
    //   })

    // gsap.utils.toArray(".product-link-wrapper").forEach(productLink => {
    //   gsap.from(productLink, {
    //     y: 100,
    //     opacity: 0,
    //     scrollTrigger: {
    //       trigger: productLink,
    //       start: "top 60%",
    //       toggleActions: "play none none reverse",
    //     },
    //   })
    // })
  }

  loadingComplete() {
    // document.getElementById("main").style.display = "block"

    // if (scroller && !isMobile && !isTablet) {
    //   const bodyScrollBar = Scrollbar.init(scroller, {
    //     delegateTo: document,
    //     alwaysShowTracks: true,
    //     syncCallbacks: true
    //   })

    //   ScrollTrigger.scrollerProxy("#scroller", {
    //     scrollTop(value) {
    //       if (arguments.length) {
    //         bodyScrollBar.scrollTop = value
    //       }
    //       return bodyScrollBar.scrollTop
    //     },
    //   })
    //   bodyScrollBar.addListener(ScrollTrigger.update)

    //   ScrollTrigger.defaults({ scroller: scroller })

    // }

    // let animTimeline = gsap.timeline()

    // let rule = CSSRulePlugin.getRule(".crop-reveal-text:before")

    // animTimeline
    //   .to(
    //     ".back",
    //     {
    //       opacity: 0.25,
    //       duration: 0.75,
    //       ease: "power4.out",
    //     },
    //     1
    //   )
    //   .staggerTo(
    //     ".fore",
    //     1.25,
    //     {
    //       ease: "power4.inOut",
    //       clipPath: "inset(0% 0 0 0)",
    //     },
    //     0.2
    //   )


    // this.setState({
    //   loading: false,
    // })
  }

  render() {
    const blok = this.props.blok
    const relatedProducts = this.props.relatedProducts

    let cat = blok.bg_cloudinary_id
    cat = cat.substr(0, cat.indexOf('/'));

    if(cat === "INNOVA") {
      cat = "Innova"
    }
    if(cat === "OUTDOOR") {
      cat = "Outdoor"
    }
    if(cat === "URBAN") {
      cat = "Urban"
    }
    
    return (
      <SbEditable content={blok} key={blok._uid}>
        {/* <div
          className={this.state.loading ? "loader" : "loader loader--done"}
        ></div> */}

        {blok.bg_cloudinary_id ? (
          <div className="bg-video">
            <CloudinaryVideo
              publicId={`ykk/${blok.bg_cloudinary_id}`}
              class_list="cover-bg"
              autoPlay={true}
            />
          </div>
        ) : (
          ""
        )}

        <div id="scroller" ref={this.scrollerRef}>
          <section className="category-intro category-intro--fixed js-hero-intro">
            <div className="container category-intro__container">
              <div className="title">
                {blok.TitleBrand ? (
                  <h1>
                    <span className="back">{blok.TitleBrand}</span>
                    <span className="fore">{blok.TitleBrand}</span>
                  </h1>
                ) : (
                  ""
                )}
                {/* <h1
                  className={
                    blok.Title.length > 35 ? "alt" : ""
                  }
                > */}
                <h1>
                  <span className="back">{blok.Title}</span>
                  <span className="fore">{blok.Title}</span>
                </h1>
              </div>
              
              <div className="flex intro">
                <div
                  className="category-intro__intro">
                  {render(blok.Intro)}
                </div>
              </div>

            </div>
            <img
              alt="Dots icon"
              aria-hidden={true}
              className="grid-of-dots category-intro__grid-of-dots"
              src={gridOfDots}
            />
          </section>

          <div className="light-bg-wrapper">
            
            {blok.Zip &&
            blok.Zip.map(childBlok => {
              return (
                <DynamicComponent
                  blok={childBlok}
                  key={childBlok._uid}
                  productID={blok.ProductID}
                  renderVideoID={blok.renderVideoID}
                  // loadingComplete={() => this.loadingComplete()}
                />
              )
            })}
            
            
            {blok.LayoutBuilder &&
            blok.LayoutBuilder.map(childBlok => {
              return (
                <DynamicComponent
                  blok={childBlok}
                  key={childBlok._uid}
                  productID={blok.ProductID}
                  renderVideoID={blok.renderVideoID}
                  loadingComplete={() => this.loadingComplete()}
                />
              )
            })}



            <section class="product-list">
              <div class="product-list__heading-container container">
                <div class="product-list__title">
                  <p>More {cat} Products</p>
                </div>
              </div>
            </section>
                  
                  
            <section className="product-list__links-wrapper">
              <div className="container">
                <div className="product-list__grid">
                  {relatedProducts &&
                    relatedProducts.map((product, index) => (
                      <Link
                        key={index}
                        to={`/${
                          product.full_slug
                        }`}
                        prefetch="true"
                        className="product-link-wrapper"
                      >
                        {
                          (product.content.Thumbnail.id) ?
                            (
                              <Img
                                loading="eager"
                                className="product-image"
                                fluid={getFluidGatsbyImage(product.content.Thumbnail.filename, {
                                  maxWidth: 900,
                                })}
                              />
                            )
                          : ''
                        }
                        
                        <h4
                          className="product-title"
                          dangerouslySetInnerHTML={{
                            __html: product.name.replace("® ", "® <br/>"),
                          }}
                        ></h4>
                        <p className="link-handle">Click to explore</p>
                      </Link>
                    ))}
                </div>
              </div>
            </section>

          </div>
          
          <Footer />
        </div>
      </SbEditable>
    )
  }
}

export default Product

import React, { Component } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { CSSPlugin } from "gsap/CSSPlugin";
import SbEditable from 'storyblok-react'
import { render } from 'storyblok-rich-text-react-renderer'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'
import CloudinaryVideo from "./cloudinaryvideoembed"

class Features extends Component {
  constructor(props) {
    super(props)
    this.sectionRef = React.createRef();
    this.titleRef = React.createRef();
    this.imageRef = React.createRef();
    this.imageElementRef = React.createRef();
    this.bulletsRef = React.createRef();
  }
  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(CSSRulePlugin);
    gsap.registerPlugin(CSSPlugin);

    let animTimeline = gsap.timeline();

    let bulletsEls = this.bulletsRef.current.getElementsByClassName('bullet')
    animTimeline.from(this.titleRef.current, {
      x: 50,
      opacity: 0,
      duration: 0.5,
      ease: "SlowMo.ease",
    }).from(this.imageRef.current, {
      clipPath:"inset(35vh 0 35vh 0)",
      opacity: 0,
      scale: 0.5,
      duration: 2,
      ease: "SlowMo.ease",
    },'-=0.5').staggerFrom(bulletsEls, 1, {
      clipPath: "inset(0 100% 0 0)",
      y: 30,
      ease: "SlowMo.ease",
    }, 0.5)


    // staggerFrom(".class", 1, {top:"+=150", opacity:0}, 0.2);

    // .from(rule, {
    //   duration: 0.25,
    //   ease: "SlowMo.ease",
    //   cssRule:{clipPath:"inset(100% 0 0 0)"}
    // })

    let stickySection = ScrollTrigger.create({
      animation: animTimeline,
      trigger: this.sectionRef.current,
      start: "top 50%",
      // end: () => "+=" + window.innerHeight*2,
      end: () => "+=" + window.innerHeight,
      // scrub: true,
      toggleActions: "play reverse play reverse"
    });

    // gsap.to(this.titleRef.current, {
    //   opacity: 0,
    //   scrollTrigger: {
    //     trigger: this.sectionRef.current,
    //     start: "top top",
    //     end: "+=1000",
    //     pin: true}
    // });


  }

  render() {
    const blok = this.props.blok
    return (
      <SbEditable content={blok}>
        <section className="features" ref={this.sectionRef}>
          <h1 className="features__title"><span ref={this.titleRef}>{blok.Title}</span></h1>
          <div className="features__bullets" ref={this.bulletsRef}>
            {
              blok.Bullets &&
                blok.Bullets.map((bullet, index) =>
                  (
                    <span className="h4 bullet">{bullet.bullet}</span>
                  )
              )
            }
          </div>
          <div className={ (blok.VideoID) ? 'features__image features__image--video' : 'features__image features__image--image' }>
            <div ref={this.imageRef}>
              {(blok.VideoID) ?
                <CloudinaryVideo
                  publicId={`ykk/${blok.VideoID}`}
                  boomerangEffect={true}
                  class_list="cover-bg"
                  autoPlay={true}
                />
                :
                <Img ref={this.imageElementRef} loading="eager" fluid={
                  getFluidGatsbyImage(blok.Image, {
                    maxWidth: 900,
                    objectFit: "contain"
                  })}
                />
              }
            </div>
          </div>
        </section>
      </SbEditable>
    )
  }

}

export default Features

import React, { Component } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import SbEditable from 'storyblok-react'
import { render } from 'storyblok-rich-text-react-renderer'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'
import CloudinaryVideo from "./cloudinaryvideoembed"
import Scrollbar from 'smooth-scrollbar'

class GalleryItem extends Component {
  render() {
    const blok = this.props.blok
    console.log(blok)
    return (
      <SbEditable content={blok}>
        <div className="video-gallery__gallery-item">
          <div className="video">
            <CloudinaryVideo
              publicId={`ykk/${blok.Video_ID}`}
              disableFill={true}
              disableGif={true}
              class_list="cover-bg"
              autoPlay={false}
              clickToPlay={true}
            />
          </div>
          {
            (blok.Caption) ?
              (
                <p>
                  { blok.Caption }
                </p>
              )
            : ''
          }
        </div>
      </SbEditable>
    )
  }
}

export default GalleryItem

import React from 'react'
import Link from 'gatsby-link'
import SbEditable from 'storyblok-react'
import CloudinaryVideo from "./cloudinaryvideoembed"
import { isMobile, isTablet } from "react-device-detect";

const HomepageVideoPanel = ({blok}) => {
  const videoRef = React.createRef();
  const mouseEnter = () => {
    videoRef.current.playVideo()
  }
  const mouseLeave = () => {
    videoRef.current.pauseVideo()
  }
  
  let backgroundColor = "black";
  let opacity = 1;

  return (
    <SbEditable content={blok} key={blok._uid}>
      <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} className="homepage-nav__item" style={{
        'backgroundColor': backgroundColor
      }} to={`/${blok.link.cached_url.replace("en/", "").replace("home", "")}`}>
        <div className="homepage-nav__content">
          <CloudinaryVideo
            ref={videoRef}
            class_list="homepage-nav__video cover-bg"
            publicId={`ykk/${blok.cloudinary_id}`}
            autoPlay={false}
            boomerangEffect={false}
          />
          <div class="image-filter-overlay"></div>
          <div className="homepage-nav__description-details">
            <h1 className="homepage-nav__title">
              <span style={{
              'opacity': opacity
            }}>{blok.title}</span>
            </h1>
          </div>
        </div>
      </Link>
    </SbEditable>
  )
}

export default HomepageVideoPanel

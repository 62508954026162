import React from 'react'
import SbEditable from 'storyblok-react'
import { render } from 'storyblok-rich-text-react-renderer'

const Title = ({blok}) => {
  return (
    <SbEditable content={blok}>
      <div className="flex no-pad-bot">
        <div className={blok.Align}>
          <div className="row-title">
            <p className="h1">{blok.Title}</p>
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default Title

import React from 'react'
import SbEditable from 'storyblok-react'
import CloudinaryVideo from './cloudinaryvideoembed'

const VideoPlayer = ({blok}) => {
  return (
    <SbEditable content={blok}>
      <div className="flex">
        <div className={blok.Align}>
          <div className="row-video">
            <div className="video-player">
              <CloudinaryVideo
                publicId={`ykk/${blok.ID}`}
                disableFill={true}
                disableGif={true}
                class_list="cover-bg"
                autoPlay={false}
                clickToPlay={true}
              />
            </div>
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default VideoPlayer

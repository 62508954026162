class ScaleSpeedPlugin {
    static pluginName = 'scaleSpeed';
  
    static defaultOptions = {
      speed: 1,
    };
  
    transformDelta(delta) {
      const { speed } = this.options;
  
      return {
        x: delta.x * speed,
        y: delta.y * speed,
      };
    }
}
export default ScaleSpeedPlugin
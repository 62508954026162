import React from "react"
import SbEditable from "storyblok-react"
import CloudinaryVideo from "./cloudinaryvideoembed"
// Image imports
import squarePatternIcon from "../images/square-pattern-icon_1.svg"

const ImageSmall = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <div className="product-image-small">
        <div className="product-image-small__container container">
          <div
            className={`product-image-small__image-wrapper ${
              blok.alignment ? `product-image-small--${blok.alignment}` : ``
            }`}
          >
            <CloudinaryVideo
              publicId={`ykk/${blok.ID}`}
              fadeInOut={true}
              autoPlay={true}
              class_list="product-image-small__cloudinary-video"
            />
          </div>
          {/* <img
            src={squarePatternIcon}
            alt="Square zip pattern icon"
            aria-hidden={true}
            className={`zip-icon ${
              blok.alignment ? `zip-icon--${blok.alignment}` : ``
            }`}
          /> */}
        </div>
      </div>
    </SbEditable>
  )
}

export default ImageSmall

import React, { Component } from 'react';
import { Video, Transformation } from 'cloudinary-react';
import play from '../images/play.svg'

class CloudinaryVideo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      videoStarted: false
    }
    this.cloudinaryVidRef = React.createRef();
    this.videoPoster = React.createRef();
    this.playVideo = this.playVideo.bind(this)
    this.pauseVideo = this.pauseVideo.bind(this)
    this.startVideo = this.startVideo.bind(this)
  }
  playVideo() {

    this.cloudinaryVidRef.current.play()
  }
  pauseVideo() {
    this.cloudinaryVidRef.current.pause()
  }
  startVideo() {
    if(this.cloudinaryVidRef.current.paused) {
      this.cloudinaryVidRef.current.play()
      this.videoPoster.current.style.opacity = "0"
    } else {
      this.cloudinaryVidRef.current.pause()
      this.videoPoster.current.style.opacity = "1"
    }
  }

  render() {
    return (
      <>
        {
          (this.props.clickToPlay) ? (
            <div ref={this.videoPoster} className="play-button" onClick={(e) => this.startVideo()}><img src={play} /></div>
          ) : ''
        }
        <Video secure className={this.props.class_list} innerRef={this.cloudinaryVidRef} crop={(this.props.disableFill) ? '' : 'fill' } autoPlay={this.props.autoPlay} loop={(this.props.clickToPlay) ? false : true} muted={(this.props.clickToPlay) ? false : true} cloudName="personatile" publicId={this.props.publicId} >
          {(!this.props.disableGif) ? (
            <Transformation flags={["awebp", "animated"]} />
          ) : ''}
          {(this.props.boomerangEffect) ? (
            <Transformation effect="boomerang" />
          ) : ''}
          {(this.props.fadeInOut) ? (
            <Transformation effect="fade:250" />
          ) : ''}
          {(this.props.fadeInOut || this.props.fadeOut) ? (
            <Transformation effect="fade:-250" />
          ) : ''}
          {(this.props.darkenVideo) ? (
            <Transformation effect="brightness:-15" />
          ) : ''}
        </Video>
      </>
    )
  }


}

// <Transformation videoSampling="10" flags={["awebp", "animated"]} width="250" height="250" crop="fill" />
export default CloudinaryVideo

import React from 'react'
import SbEditable from 'storyblok-react'
import { render } from 'storyblok-rich-text-react-renderer'
import CloudinaryVideo from "./cloudinaryvideoembed"

const Gif = ({blok}) => {
  return (
    <SbEditable content={blok}>
      <div className="flex">
        <div className={blok.Align}>
          <div className="row-gif">
            <div className="product-gif">
              <CloudinaryVideo
                publicId={`ykk/${blok.ID}`}
                fadeInOut={true}
                class_list="cover-bg"
                autoPlay={true}
              />
            </div>
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default Gif

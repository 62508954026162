import React from "react"
import SbEditable from "storyblok-react"
import { render } from "storyblok-rich-text-react-renderer"
// Image imports
import squarePatternIcon from "../images/square-pattern-icon_1.svg"

const ProductRangeTitle = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <section className="product-list">
        <div className="product-list__heading-container container">
          <div className="product-list__title">
            {render(blok.category_title)}
          </div>
          {/* <img
            src={squarePatternIcon}
            alt="Square zip pattern icon"
            aria-hidden={true}
            className="product-list__zip-icon"
          /> */}
        </div>
      </section>
    </SbEditable>
  )
}

export default ProductRangeTitle

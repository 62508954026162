import React from 'react'
import SbEditable from 'storyblok-react'
import { render } from 'storyblok-rich-text-react-renderer'
import gridOfDots from "../images/grid-of-dots.svg"
import gridOfDotsHalf from "../images/grid-of-dots-half.svg"

const TextBlock = ({blok}) => {
  return (
    <SbEditable content={blok}>
      <section className={`text-block ${(blok.Alignment) ? `text-block--${blok.Alignment}` : ``}`}>
        <div className="container">
          <div className="flex">
            <div className="text-block__wrapper fade">
              {render(blok.Text)}
            </div>
          </div>
        </div>
        <img alt="Dots icon" ariaHidden={true} className="grid-of-dots text-block__grid-of-dots" src={gridOfDots} />
        <img alt="Dots icon" ariaHidden={true} className="grid-of-dots-half text-block__grid-of-dots-half" src={gridOfDotsHalf} />
      </section>
    </SbEditable>
  )
}

export default TextBlock

import React from 'react'
import SbEditable from 'storyblok-react'
import { render } from 'storyblok-rich-text-react-renderer'

const BulletPoint = ({blok}) => {
  return (
    <SbEditable content={blok}>
      <div className="flex no-pad">
        <div className={blok.Align}>
          <p className="h3 row-bullet-point">{blok.BulletPoint}</p>
        </div>
      </div>
    </SbEditable>
  )
}

export default BulletPoint

import React, { Component } from "react"
import SbEditable from "storyblok-react"
import { render } from "storyblok-rich-text-react-renderer"

const BulletPointList = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <section className="bullet-point-list">
        <div className="bullet-point-list__container container">
          <h2 className="bullet-point-list__title">{blok.title}</h2>
          <div className="bullet-point-list__list">{render(blok.list)}</div>
        </div>
      </section>
    </SbEditable>
  )
}

export default BulletPointList

import React from "react"
import SbEditable from "storyblok-react"
import CloudinaryVideo from "./cloudinaryvideoembed"

const VideoLarge = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <div className="product-video-large">
        <div className="container">
          <div className="product-video-large__video-wrapper">
            <CloudinaryVideo
              publicId={`ykk/${blok.render_video_ID}`}
              disableFill={true}
              disableGif={true}
              autoPlay={false}
              clickToPlay={true}
              class_list="product-video-large__cloudinary-embed"
            />
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default VideoLarge

import React, { useState, Component } from 'react'
import { gsap } from 'gsap'
import SbEditable from 'storyblok-react'
import { render } from 'storyblok-rich-text-react-renderer'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'
import CloudinaryVideo from "./cloudinaryvideoembed"

class FeatureTabs extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedTab: 0
    }
    this.selectTab = this.selectTab.bind(this)
    this.imageRef = React.createRef();
  }

  componentDidMount() {
    gsap.from(this.imageRef.current, {
      y: 125,
      opacity: 0,
      scrollTrigger: {
        trigger: this.imageRef.current,
        start: "top 50%",
        toggleActions: "play none none none",
        // markers:true
      },
    });
  }

  selectTab(tabNum) {
    this.setState({
      selectedTab: tabNum,
    });
  }

  render() {
    const blok = this.props.blok
    return (
      <SbEditable content={blok}>
        <section className="features-tabs">
          <div className="container container--slim">
            <div className="features-tabs__row">
              <div className="features-tabs__content">
                <div className="features-tabs__titles">
                  {blok.Tabs &&
                    blok.Tabs.map((tab, index) => (
                      <button key={`tab-button-${index}`} onClick={() => this.selectTab(index)} className={(index === this.state.selectedTab) ? 'features-tabs__title features-tabs__title--selected' : 'features-tabs__title' }>{tab.Title}</button>
                    ))
                  }
                </div>
                {blok.Tabs &&
                  blok.Tabs.map((tab, index) => (
                    <div key={`tab-content-${index}`} className={(index === this.state.selectedTab) ? 'features-tabs__text features-tabs__text--selected' : 'features-tabs__text' }>
                      {render(tab.Content)}
                    </div>
                  ))
                }

              </div>
              <div className="features-tabs__images" ref={this.imageRef}>
                {blok.Tabs &&
                  blok.Tabs.map((tab, index) => (
                    <div key={`tab-media-${index}`} className={(index === this.state.selectedTab) ? 'features-tabs__image features-tabs__image--selected' : 'features-tabs__image' }>
                      {(tab.Video) ?
                        <div className="features-tabs__video">
                          <CloudinaryVideo
                            publicId={`ykk/${tab.Video}`}
                            boomerangEffect={true}
                            class_list="cover-bg"
                            autoPlay={true}
                          />
                        </div>
                        : <Img loading="eager" fluid={
                          getFluidGatsbyImage(tab.Image, {
                            maxWidth: 900
                          })
                        } />
                      }

                    </div>
                  ))
                }
              </div>
            </div>

          </div>
        </section>
      </SbEditable>
    )
  }
}

export default FeatureTabs

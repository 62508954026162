import React, { useState, useEffect } from "react"

const SampleButton = ({ productID }) => {
  const [initialized, setInitialized] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [windowLoaded, setWindowLoaded] = useState(false)

  useEffect(() => {
    if (!windowLoaded) {
      if (typeof window !== "undefined" && window) {
        setWindowLoaded(true)
      }
    }
  }, [windowLoaded])

  useEffect(() => {
    if (!initialized && windowLoaded) {
      //You can now use your localStorage commands
      let productIds = JSON.parse(localStorage.getItem("productIds") || "0")
      if (!productIds) {
        productIds = []
      }
      if (productIds.includes(productID)) {
        setIsActive(true)
      }
      setInitialized(true)
    }
  }, [initialized, windowLoaded])

  const addToSamplePack = () => {
    let productIds = JSON.parse(localStorage.getItem("productIds") || "0")
    if (!productIds) {
      productIds = []
    }
    if (!productIds.includes(productID)) {
      productIds.push(productID)
      localStorage.setItem("productIds", JSON.stringify(productIds))
      window.dispatchEvent(new Event("storage"))
      setIsActive(true)
    } else {
      let newProductIDs = productIds.filter(product => product != productID)
      localStorage.setItem("productIds", JSON.stringify(newProductIDs))
      window.dispatchEvent(new Event("storage"))
      setIsActive(false)
    }
  }

  return initialized ? (
    <button
      className={
        isActive
          ? "zip-three-sixty-wrapper__add-to-sample-btn zip-three-sixty-wrapper__add-to-sample-btn--ticked"
          : `zip-three-sixty-wrapper__add-to-sample-btn`
      }
      onClick={addToSamplePack}
    >
      Add to Sample Pack
      {/* <div className="request-a-sample-btn__icon request-a-sample-btn__icon--plus">
        <span className="plus"></span>
        <span className="tick"></span>
        <svg width="52px" height="52px" viewBox="0 0 52 52">
          <path
            className="zipstroke"
            fill="none"
            stroke="white"
            strokeWidth="1.5"
            d="M26,51C12.2,51,1,39.8,1,26S12.2,1,26,1s25,11.2,25,25S39.8,51,26,51z"
          />
          <path
            className="zipdashes"
            fill="none"
            stroke="white"
            strokeWidth="1.5"
            d="M26,51C12.2,51,1,39.8,1,26S12.2,1,26,1s25,11.2,25,25S39.8,51,26,51z"
          />
        </svg>
      </div> */}
    </button>
  ) : (
    ""
  )
}

export default SampleButton
